import React, { Component } from 'react';
import { Switch, Router, Route } from 'react-router-dom';
import HomepageContainer from './containers/HomepageContainer';
import history from './history';
import './App.css';
import Registration from './containers/Registration/Registration';
import NavBar from './component/HeaderComponent/NavBar';
import FooterPage from './component/FooterPage';
import ContactUsContainer from './containers/ContactUsContainer';
import OfficeStaff from './component/OfficeStaff/OfficeStaff';
import Library from './containers/Library';
import EventContainer from './containers/EventContainer';
import LaboratoryContainer from './containers/LaboratoryContainer';
import FacultyContainer from './containers/FacultyContainer';
import StaffContainer from './containers/StaffContainer';
import AchievementsContainer from './containers/AchievementsContainer';
import BtechContainer from './containers/BtechContainer';
import UsefulLinks from './containers/UsefulLinks';
import FullNews from './component/News/FullNews';
import AcademicCalender from './containers/AcademicCalender';
import HolidayList from './containers/HolidayList';
import ComingSoon from './containers/ComingSoon';
import AlumniContainer from './containers/AlumniContainer';
import ProjectContainer from './containers/ProjectContainer';
import TimeTable from './containers/TimeTable';
import TestContainer from './containers/TestContainer';
import PictureGallaryContainer from './containers/PictureGallaryContainer';
import NewsLetter from './containers/NewsLetter';
import PlacementContainer from './containers/PlacementContainer';
import TrainingContainer from './containers/TrainingContainer';
import TpSummaryContainer from './containers/TpSummaryContainer';
import MessageFromHod from './containers/MessageFromHod';
import FacultyPublications from './containers/FacultyPublications';
import SponsoredPublications from './containers/SponsoredPublications';
import ResearchScholars from './containers/ResearchScholars';
import CommitteeContainer from './containers/CommitteeContainer';
import PoContainer from './containers/PoContainer';
import PsoContainer from './containers/PsoContainer';
import PesContainer from './containers/PesContainer';
import HodContainer from './containers/HodContainer';
import FormerFacultyContainer from './containers/FormerFacultyContainer';
import ActivitiesContainer from './containers/ActivitiesContainer';
import BestPracticesContainer from './containers/BestPracticesContainer';
import PatentContainer from './containers/PatentContainer';
import CollaborationsContainer from './containers/CollaborationsContainer';
import HistoryContainer from './containers/HistoryContainer';
import CourseMaterialContainer from './containers/CourseMaterialContainer';
import ResearchLaboratories from './containers/ResearchLaboratories';
import GalleryContainer from './containers/GalleryContainer';
import FacultyInformationContainer from './containers/FacultyInformationContainer';
import FormerFacultyOneContainer from './containers/FormerFacultyOneContainer';


class App extends Component {
  render() {
    return (
      <Router history={history}>
        <div>
          <NavBar />
          <div className="main-body">
            <Switch>
              <Route exact path="/" component={HomepageContainer} />
              <Route exact path="/test" component={TestContainer} />
              <Route exact path="/newsletter" component={NewsLetter} />
              <Route exact path="/educationaltour" component={ComingSoon} />
              <Route exact path="/training" component={TrainingContainer} />
              <Route exact path="/placement" component={PlacementContainer} />
              <Route exact path="/tpsummary" component={TpSummaryContainer} />
              <Route exact path="/contactus" component={ContactUsContainer} />
              <Route exact path="/registration" component={Registration} />
              <Route exact path="/office" component={OfficeStaff} />
              <Route exact path="/library" component={Library} />
              <Route exact path="/notifications" component={FullNews} />
              <Route exact path="/gallery" component={PictureGallaryContainer} />
              <Route exact path="/messagefromhod" component={MessageFromHod} />
              <Route exact path="/facultypublications" component={FacultyPublications} />
              <Route exact path="/sponsoredprojects" component={SponsoredPublications} />
              <Route exact path="/researchlab" component={ResearchLaboratories} />
              <Route exact path="/researchscholars" component={ResearchScholars} />
              <Route exact path="/activities" component={ActivitiesContainer} />

              <Route
                exact
                path="/events/workshop"
                render={(props) => <EventContainer
                  event_type="WORKSHOPS"
                  {...props} />}
              />
              <Route
                exact
                path="/events/talks"
                render={(props) => <EventContainer
                  event_type="TALKS"
                  {...props} />}
              />

              <Route
                exact
                path="/events/seminars"
                render={(props) => <EventContainer
                  event_type="SEMINARS"
                  {...props} />}
              />

              <Route
                exact
                path="/events/conference"
                render={(props) => <EventContainer
                  event_type="CONFERENCE"
                  {...props} />}
              />

              <Route
                exact
                path="/events/fdp"
                render={(props) => <EventContainer
                  event_type="FACULTY DEVELOPMENT PROGRAM"
                  {...props} />}
              />

<Route
                exact
                path="/events/webiner"
                render={(props) => <EventContainer
                  event_type="WEBINAR"
                  {...props} />}
              />

<Route
                exact
                path="/events/lectureseries"
                render={(props) => <EventContainer
                  event_type="LECTURE SERIES"
                  {...props} />}
              />


<Route
                exact
                path="/events/recent"
                render={(props) => <EventContainer
                  event_type="RECENT_EVENT"
                  {...props} />}
              />


<Route
                exact
                path="/events/other"
                render={(props) => <EventContainer
                  event_type="OTHER"
                  {...props} />}
              />

              <Route exact path="/laboratory" component={LaboratoryContainer} />
              <Route exact path="/faculty" component={FacultyContainer} />
              {/* <Route exact path="/facultyinfo" component={FacultyInformationContainer} /> */}
              <Route exact path="/staff" component={StaffContainer} />
              <Route exact path="/alumni" component={AlumniContainer} />
              <Route exact path="/projects" component={ProjectContainer} />
              <Route exact path="/bestpractices" component={BestPracticesContainer} />
              <Route exact path="/achievements" component={AchievementsContainer} />
              <Route exact path="/btech" component={BtechContainer} />
              <Route exact path="/patents" component={PatentContainer} />
              
              <Route exact path="/collaborations" component={CollaborationsContainer} />
              <Route exact path="/acalender" component={AcademicCalender} />
              <Route exact path="/timetable" component={TimeTable} />
              <Route exact path="/holidaylist" component={HolidayList} />
              <Route exact path="/usefullinks" component={UsefulLinks} />
              <Route exact path="/committee" component={CommitteeContainer} />
              <Route exact path="/po" component={PoContainer} />
              <Route exact path="/history" component={HistoryContainer} />
              <Route exact path="/coursematerials" component={CourseMaterialContainer} />
              
              <Route exact path="/pso" component={PsoContainer} />
              <Route exact path="/pes" component={PesContainer} />
              <Route exact path="/hod" component={HodContainer} />
              <Route exact path="/formerfaculty" component={FormerFacultyContainer} />
              <Route exact path="/formerfacultylist" component={FormerFacultyOneContainer} />

            </Switch>
          </div>
          <FooterPage />
        </div>
      </Router>
    );
  }
}

export default App;
